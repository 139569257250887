/* General styles */
.content {
  text-align: center;
  padding: 0 20px;
  width: 100%;
}

.our {
  color: blue;
  font-size: 28px;
  font-weight: 700;
}

/* Section 1 styles */
.section1 {
  margin: 150px 0 10px 150px;
}

.section1 .sec1_div1 h1 {
  font-size: 50px;
  font-weight: bold;
  color: #7a7f4f;
}

.section1 .sec1_div1 span {
  color: blue;
}

.section1 h1, .section1 p {
  margin: 0;
  padding: 0;
}

/* Section 2 styles */
.section2 {
  text-align: center;
  width: 100%;
  margin: 0 100px;
  padding-bottom: 0;
}

.section2 .sec2_div1 p {
  font-size: 28px;
  font-weight: 700;
  text-align: justify;
}

.section2 .sec2_div1 span {
  font-size: 18px;
  font-style: italic;
}

/* Section boxes */
.section-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

.section-boxes .box {
  background-color: #f0f0f0;
  border: 2px solid  goldenrod;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10%;
  height: 150px;
  width: 30%; /* Adjusted width */
  padding: 20px;
  margin: 10px;
  text-align: center;
  line-height: 1.5;
}

.section-boxes .box:hover {
  background-color: #e8b602;
  color: white;
}

/* Section actions */
.section-actions {
  padding: 80px 100px;
  text-align: center;
}

.section-actions h2, .section-actions span {
  font-size: 50px;
  font-weight: 700;
}

.section-actions .action-box, .section-actions .action-box1 {
  background-color: rgb(250, 250, 250);
  border-color: #e8b602;
 border-width: 2px;
  border-radius: 10%;
  margin:10px;
  padding: 30px;
  display: inline-block;
  
}

.section-actions .action-box img, .section-actions .action-box1 img {
  width: 60px;
  height: 60px;
  float: left;
}

.section-actions .action-box span, .section-actions .action-box1 span {
  color: #353532;
  font-size: 50px;
  font-weight: 600;
}

.section-actions .action-box p, .section-actions .action-box1 p {
  float: right;
  font-size: 20px;
}

/* Total Clicks section */
.totalClick_sec {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

.abovecont {
  text-align: center;
  /* margin-top: 30px; Adjust as needed */
  margin-bottom: 30px; /* Adjust as needed */
  padding: 20px;
  background-color: #f0f0f0; /* Example background color */
}

.total_click {
  margin-top: 30px; /* Adjust as needed */
  margin-right: 30px;
  text-align: center;
  padding: 20px;
  border-radius: 5%;
  background-color: #f0f0f0; /* Example background color */
}

.click {
  border: 2px solid goldenrod;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  text-align: center;
  background-color: #f6f6f6;
  cursor: pointer;
}

/* Nested content */
.nested {
  display: none;
}

.nested.active {
  display: block;
}

/* List styles */
.list li {
  text-align: left;
  color: #3333ef;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
}

.list li a {
  text-decoration: none;
  color: #08026e;
  font-size: 15px;
  font-weight: 600;
}

.list ul li {
  transform: none;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .section1 {
    margin: 50px 0;
    text-align: center;
  }

  .section1 .sec1_div1 h1 {
    font-size: 40px;
  }

  .section2 {
    margin: 0 30px;
  }

  .section2 .sec2_div1 p {
    font-size: 18px;
    margin: 0 30px;
  }

  .section-boxes .box {
    width: 45%; /* Adjusted width for smaller screens */
  }

  .section-actions {
    padding: 50px 30px;
  }

  .click {
    margin: 50px auto;
    width: calc(90% - 50px);
  }

  .section-actions .action-box, .section-actions .action-box1 {
    margin: 20px auto;
  }

  .totalClick_sec {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items vertically */
  }
}

@media (max-width: 576px) {
  .section1 .sec1_div1 h1 {
    font-size: 30px;
  }

  .section-boxes .box {
    width: 100%; /* Full width for extra small screens */
  }

  .section-actions h2 {
    font-size: 48px;
  }

  .section-actions p {
    font-size: 18px;
    margin-top: 20px;
  }

  .section-actions span {
    font-size: 40px;
  }

  .click {
    padding: 30px;
    margin-top: -13%;
  }

  .totalClick_sec {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items vertically */
  }

  .total_click {
    border-radius: 5%;
    margin-right: 0px;
  }
  .section-boxes .box {
    width: 45%; /* Adjusted width for smaller screens */
  }
}
