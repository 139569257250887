/* Global footer styles */
.global-footer, .global-footer2 {
  background-color: #273374;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.global-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.global-footer-section {
  flex: 1;
  min-width: 250px;
  margin: 10px;
}

.global-footer-section h3 {
  margin-bottom: 10px;
  color: #FFD700;
}

.global-footer-section ul {
  list-style: none;
  padding: 0;
}

.global-footer-section ul li {
  margin: 5px 0;
}

.global-footer-section ul li a {
  color: white;
  text-decoration: none;
}

.global-footer2 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.global-footer2 p {
  margin: 10px;
  flex: 1 100%;
  text-align: center;
}

.global-socials-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.global-socials-icons a {
  margin: 0 10px;
}

.global-socials-icons img {
  width: 20px;
  height: 20px;
}

/* WhatsApp icon */
.global-whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  z-index: 100;
  animation: bounce 2s infinite;
}

.global-whatsapp-icon img {
  width: 100%;
  height: 100%;
}

/* Bounce animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media (max-width: 768px) {
  .global-footer {
    flex-direction: column;
    align-items: center;
  }

  .global-footer2 {
    flex-direction: column;
    align-items: center;
  }

  .global-footer-section {
    text-align: center;
  }
}
