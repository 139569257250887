/* MainHome styles */
.mainhome-body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  padding: 20px;
  margin: 0;
}

.mainhome-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mainhome-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 10px;
}

.mainhome-homes {
  color: blue;
}

.mainhome-subtitle {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 30px;
}

.mainhome-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
  font-family: 'Times New Roman', Times, serif;
}

.mainhome-text-container {
  flex: 1;
  padding: 20px;
}

.mainhome-image-container {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.mainhome-image-container img {
  max-width: 80%; /* Adjusted max-width to make the images smaller */
  height: auto;
  border-radius: 8px;
}

.mainhome-content-last {
  padding: 20px;
  text-align: center;
  background: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .mainhome-content {
    flex-direction: column;
  }

  .mainhome-title {
    font-size: 2em;
  }

  .mainhome-subtitle {
    font-size: 1em;
  }

  .mainhome-image-container img {
    max-width: 100%; /* Ensures images are responsive on smaller screens */
  }
}

@media (max-width: 480px) {
  .mainhome-title {
    font-size: 1.5em;
  }

  .mainhome-subtitle {
    font-size: 0.9em;
  }

  .mainhome-text-container, .mainhome-image-container {
    padding: 10px;
  }

  .mainhome-image-container img {
    max-width: 100%; /* Ensures images are responsive on smaller screens */
  }
}
