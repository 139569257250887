/* carousel */


@media (min-width: 1200px) {

    .carousel-img{
        width: 90%;
        height: 500px;
        margin-left: 60px;
    
    }
    
}

/* @media (max-width: 798px) {

   
} */