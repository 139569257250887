/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.aboutus-boddy {
  padding: 20px;
}

/* Home banner */
#aboutus-home-banner {
  position: relative;
  text-align: center;
  color: white;
}

#aboutus-home-banner img {
  width: 100%;
  height: 450px;
}

#aboutus-home-banner h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5em;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 10px;
}

#aboutus-home-banner h1 span {
  color: #FFD700;
}

/* About section */
#aboutus-about {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

#aboutus-about .aboutus-text-containers {
  flex: 1;
  padding: 20px;
}

#aboutus-about img {
  flex: 1;
  width: 100%;
  max-width: 500px; /* Add max-width for responsiveness */
  height: auto;
  border-radius: 10px;
  margin: 20px 0;
}

.aboutus-us {
  color: #FFD700;
}

#aboutus-first,
#aboutus-next {
  margin: 10px 0;
}

/* Services section */
.aboutus-conceptBoxCover {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

#aboutus-services {
  text-align: center;
}

#aboutus-services h2 {
  margin-bottom: 20px;
}

#aboutus-services .aboutus-service {
  display: inline-block;
  width: 45%;
  max-width: 300px; /* Add max-width for responsiveness */
  margin: 10px 2.5%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
  transition: transform 0.3s ease-in-out;
}

#aboutus-services .aboutus-service:hover {
  transform: scale(1.05);
}

#aboutus-services .aboutus-service-icon {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

/* Core values section */
#aboutus-core-values {
  text-align: center;
  margin: 20px 0;
}

#aboutus-core-values h2 {
  margin-bottom: 10px;
}

#aboutus-core-values p {
  margin-bottom: 20px;
}

/* Vision and features section */
.aboutus-horizontal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

#aboutus-vision,
#aboutus-features {
  flex: 1;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
}

#aboutus-vision .aboutus-vision-icon,
#aboutus-features .aboutus-features-icon {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

#aboutus-features ul {
  list-style: none;
}

#aboutus-features ul li {
  margin: 5px 0;
}

/* Locations section */
#aboutus-locations {
  margin: 20px 0;
}

#aboutus-locations .aboutus-location {
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
}

/* Home brands section */
.aboutus-home-brands {
  margin: 20px 0;
}

.aboutus-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.aboutus-home-brandle {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.aboutus-home-brandle h2 {
  margin-top: 10px;
}

.aboutus-slider {
  flex: 1;
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.aboutus-slider div {
  flex: none;
  scroll-snap-align: start;
  margin: 0 5px;
}

.aboutus-slider div img {
  width: 80px; /* Smaller width for images */
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.aboutus-slider div img:hover {
  transform: scale(1.1);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  #aboutus-about {
    flex-direction: column;
  }

  #aboutus-about img {
    margin-top: 0;
  }

  .aboutus-horizontal-container {
    flex-direction: column;
  }

  #aboutus-vision,
  #aboutus-features {
    margin: 10px 0;
  }

  .aboutus-slider {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  #aboutus-home-banner h1 {
    font-size: 1.8em;
    padding: 5px 10px;
  }

  #aboutus-home-banner img {
  height: auto;
  border-radius: 15px;
  }

  #aboutus-services .aboutus-service {
    width: 100%;
    margin: 10px 0;
  }

  .aboutus-slider div img {
    width: 60px; /* Even smaller width for mobile devices */
  }
}
