/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Header styles */
header {
  background-color: #00137e;
  color: #fff;
  padding: 10px 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: top 0.3s; /* Smooth transition for the hide/show effect */
}

header.hidden {
  top: -100px; /* Adjust this value based on your header's height */
}

/* Social media icons */
.global-social-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  z-index: 1000;
}

.global-social-icons a {
  margin: 10px 0;
}

.global-social-icons img {
  width: 20px;
  height: 20px;
}

/* Navigation styles */
.global-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.global-logo {
  height: 50px;
}

.global-nav-contents {
  list-style: none;
  display: flex;
}

.global-nav-contents li {
  margin: 0 15px;
  margin-top: 10px;
}

.global-nav-contents a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
}

.global-nav-contents a:hover,
.global-nav-contents .global-active {
  color: #FFD700;
}

/* Responsive navigation */
.global-navbar {
  display: none;
}

.global-open-slide a img {
  width: 30px;
  height: 30px;
}

/* Side menu styles */
.global-side-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  background-color: #273374;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.global-side-nav a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 1.2em;
  color: #fff;
  display: block;
  transition: 0.3s;
}

.global-side-nav a:hover {
  color: #FFD700;
}

.global-btn-close {
  position: absolute;
  top: 0;
  right: 22px;
  font-size: 36px;
}

@media (max-width: 768px) {
  .global-nav-contents {
    display: none;
  }

  .global-navbar {
    display: block;
  }

  .global-open-slide {
    cursor: pointer;
  }

  .global-social-icons {
    display: none;
  }
}
