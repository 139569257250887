/* Contactus styles */
.contactus-main {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    padding: 20px;
    margin: 0;
  }
  
  .contactus-intro, .contactus-intro1, .contactus-contact, .contactus-head2, .contactus-location-images {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .contactus-intro h4, .contactus-intro1 h2, .contactus-contact h4, .contactus-head2 h2 {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .contactus-intro h2 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .contactus-intro1 ul {
    list-style: none;
    padding: 0;
  }
  
  .contactus-intro1 li {
    margin: 10px 0;
    font-size: 1.2em;
  }
  
  .contactus-contact-methods {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .contactus-email, .contactus-call-us {
    flex: 1;
    min-width: 300px;
    text-align: center;
  }
  
  .contactus-first {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .contactus-next {
    margin-top: 5px;
  }
  
  .contactus-map-container {
    text-align: center;
  }
  
  .contactus-map-container iframe {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .contactus-intro h2 {
      font-size: 1.5em;
    }
  
    .contactus-intro1 li, .contactus-contact-methods .contactus-first {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .contactus-intro h2 {
      font-size: 1.2em;
    }
  
    .contactus-intro1 li, .contactus-contact-methods .contactus-first {
      font-size: 0.9em;
    }
  
    .contactus-contact-methods {
      flex-direction: column;
    }
  
    .contactus-email, .contactus-call-us {
      margin-bottom: 20px;
    }
  }
  