/* General Styles */
body {
  font-family: Arial, sans-serif;
}

.swara-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.swara-projects-section h2 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.swara-description {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 20px;
}

/* Tabs Styles */
.swara-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.swara-tab {
  margin: 0 15px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2em;
  border-bottom: 2px solid transparent;
  background-color: #f0f0f0;
  color: #00137e;
  transition: background-color 0.3s, color 0.3s, border-bottom-color 0.3s;
}

.swara-tab:hover {
  background-color: #00137e;
  color: #fff;
  border-bottom-color: #ffd700;
}

.swara-tab.swara-active {
  background-color: #00137e;
  color: #fff;
  border-bottom-color: #ffd700;
}


.swara-active {
  border-color: #00137e;
}

/* Loading Spinner */
.swara-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.swara-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #00137e;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Projects Content */
.swara-content {
  display: none;
}

.swara-active {
  display: block;
}

#swara-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.swara-property {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 300px;
  width: 100%;
}

.swara-image-link {
  display: block;
}

.swara-property-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.swara-property-details {
  padding: 15px;
  text-align: center;
}

.swara-horizontal-content {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  justify-content: space-around;
}

.swara-horizontal-content li {
  font-size: 1em;
  color: #333;
}

.swara-know-more {
  display: inline-block;
  margin-top: 10px;
  color: #00137e;
  text-decoration: none;
}

.swara-know-more:hover {
  text-decoration: underline;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .swara-property-image {
    height: 150px;
  }

  .swara-horizontal-content {
    flex-direction: column;
  }

  .swara-horizontal-content li {
    margin-bottom: 5px;
  }

  .swara-tab {
    font-size: 1em;
    padding: 8px 15px;
  }

  .swara-tab:hover {
    border-bottom-color: #ffb700; /* A slightly different color for hover effect on smaller screens */
  }

  .swara-tab.swara-active {
    border-bottom-color: #ffb700; /* Ensure the active tab border color matches the hover effect on smaller screens */
  }

}

@media (max-width: 480px) {
  .swara-property-image {
    height: 120px;
  }

  .swara-tab {
    font-size: 0.9em;
    padding: 6px 10px;
    margin: 0 10px;
  }

  .swara-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .swara-tab:hover {
    border-bottom-color: #ff9000; /* A different color for hover effect on even smaller screens */
  }

  .swara-tab.swara-active {
    border-bottom-color: #ff9000; /* Ensure the active tab border color matches the hover effect on even smaller screens */
  }
}
